mcWeb.backorders = new function() {
    var $ = jQuery;
    var self = this;
    this.load = function (plid, popoverJson) {
       var mypopover = $('.backorder-info[data-plid=' + plid + ']').popover( popoverJson );
        $('.backorder-info[data-plid=' + plid + ']')
            .on('shown.bs.popover', function (me) {                
                console.log(me);
                console.log(mypopover);
            });

        
    };
    
};  
